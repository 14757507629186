.caseStudy {
  
  border-top: 0.5px solid rgba(255, 255, 255, 0.4);
  background-color: rgb(33, 33, 33);
  color: rgb(173, 173, 173);
  max-height: 50;
  font-family: var(--primaryFont);
  line-height: 1.5rem;
}

.caseStudy div{
   align-items: center;
    justify-content: center;
}

.caseStudy-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.caseStudy-column-text{
  padding-right: 2rem;
  padding-left: 2rem;
  align-content: flex-start;
  align-items: flex-start;
  flex: 6;
   display: flex;
   flex-direction: column;
}

.caseStudy-column-image{
   align-content: center;
  align-items: center;
   flex: 4;
    display: flex;
      margin-left: auto;
  margin-right: auto;
}


.caseStudy p {
  padding-top: 10px;
  text-align: justify;
  padding-bottom: 10px;
  font-weight: 500;
  color: rgb(234, 234, 234);
}

.caseStudy h1 {
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(234, 234, 234);
}

.caseStudy h2 {
   line-height: 2.5rem;
  padding-top: 40px;
  padding-bottom: 20px;
   text-align:left;
      font-size: 3.5rem;
   
}

.caseStudy a {
  color: rgb(222, 74, 66);
  text-decoration: underline;
}

.caseStudy img {
  object-fit: contain;
  padding: 10px;
  max-width: 80vw;
  max-height: 80vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  margin: 50px;

  box-shadow: 19px 27px 89px 17px rgba(0,0,0,0.6);
  -webkit-box-shadow: 19px 27px 89px 17px rgba(0,0,0,0.6);
  -moz-box-shadow: 19px 27px 89px 17px rgba(0,0,0,0.6);
}

.caseStudy-phone-image {
 background:   rgba(0,0,0,0.6);
 background: rgb(36,0,9);
background: linear-gradient(155deg, rgba(36,0,9,1) 0%, rgba(121,9,14,1) 35%, rgba(79,18,2,1) 100%);
 
}

.caseStudy-phone-image--invert {
 background:   #c93210;
  background:  linear-gradient(330deg,#870c0c 0%, #c93210 80%);
  background: -webkit-linear-gradient(330deg,#870c0c 0%, #c93210 80%);
  background: -moz-linear-gradient(330deg,#870c0c 0%, #c93210 80%);
}


/* overriding from previous common.css file */
.line-styling {
  position: relative;
  top: 0px;
}

@media screen and (max-width: 992px){

    .caseStudy-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
        border-top: none;
    }

    .caseStudy-column-text{
       width:100%;
    }

      .caseStudy-column-image{
       width:100%;
    }

  }

@media screen and (max-width: 600px) {
  .caseStudy {
    border-top: none;
  }
}


