

.container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.videoBG body {
        background: url('/../apportunity/src/assets/jpg/video/intro.jpg');
        background-size: cover;
    }

.container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}

html,body {
    width:100vw;
    height: 100vh;
    margin: 0;
}
#videoBG {
    position:fixed;
    z-index: -1;
}
@media (min-aspect-ratio: 16/9) {
    #videoBG {
        width:100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 16/9) {
    #videoBG { 
        width:auto;
        height: 100%;
    }
}
@media (max-width: 767px) {
    #videoBG {
        width:auto;
        height: 100%;
    }
    
}