.hover-element a{
    text-align: right;
      color: darkred;
      font-weight: bold;
      font-size: 1.1rem;

}

.hover-element {
  overflow: hidden;
  position: relative;
  display: inline-block;

}

.hover-element a::before,
.hover-element a::after {
 content: '';
color: white;
  position: absolute;
  width: 100%;
  left: 0;
}
.hover-element a::before {
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}
.hover-element a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  color:  white;
}

.hover-element a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.hover-element a:hover::after {
  transform: translate3d(0, 0, 0);
}

.hover-element a span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.hover-element a:hover span {
  transform: translate3d(-200%, 0, 0);
}