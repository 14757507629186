.services {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.2rem;
}

.services-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.services-image {
    display:flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
}

.services-image > img{
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    max-width: 100%;
    max-height: 100%;
}

.services-header > h1 {
    margin-bottom: 20px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.services-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.services-body > p {
    font-family: var(--primaryFont);
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 6rem;
}


.achievement-body >h2 {
    font-family: var(--primaryFont);
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 143%;
    margin-bottom: 6px;
}


.services-bodycontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;
    width: 100%;
    flex-wrap: wrap;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .services {
        padding: 2.7rem;
    }
    .services-body > p {
        width: 70%;
    }
}


@media screen and (max-width: 992px) {
    .services {
        padding: 1.7rem;
    }

    .services-body > p {
        font-size: 1.25rem;
        width: 85%;
        text-align: center;
        margin-bottom: 4rem;
    }

    .services-bodycontainer {
        gap: 2.5rem;
    }
}

@media screen and (max-width: 800px) {
    .services {
        padding: 1rem;
    }
    .services-header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .services-body > p {
        font-size: 1.05rem;
        width: 95%;
        text-align: center;
        margin-bottom: 3rem;
    }

    .services-bodycontainer {
        gap: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .services {
        padding: 0.5rem;
    }
    .services-header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .services-body p{
        width: 80%;
    }
    .services-bodycontainer {
        gap: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}