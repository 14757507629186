p {
  word-spacing: 5px;
}

.line-styling{
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 85%;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
.line-styling .style-circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
.line-styling .style-line{
    width: 95%;
    height: 5px;
    border-radius: 10px;
  }
  
  @media screen and (max-width: 800px){
    .line-styling .style-circle{
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  
  }
  
  @media screen and (max-width: 600px){
  
   .line-styling .style-circle{
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    
    .line-styling .style-line{
      width: 93%;
      height: 3px;
      border-radius: 10px;
    }
}
  