.video-container{
   width: 90vw;;
    
}   

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
    display: flex;
  justify-content: center;
  align-self: center;

}

.video-responsive iframe {
    border-radius: 30px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
    display: flex;
  justify-content: center;
   align-self: center;
}
