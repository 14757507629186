
.blog-detail--header {
     font-family: "var(--primaryFont)", sans-serif;
 
    display: flex;
  flex-direction:column;
    text-align: right;
    align-items: flex-end;
}
.blog-detail--header h1 {
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgba(234, 234, 234, 1);
  margin-left: 200px;
  margin-right: 20px;
   font-size: 2.5rem;
}


.blog-detail--header h5 {
 color: rgba(234, 234, 234, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.2rem;
  margin-right: 20px;
}

@media (max-width: 600px) {
 .blog-detail--header {
   
    text-align: center;
    align-items: center;
}

.blog-detail--header h1 {
      margin-left: 20px;
}

}