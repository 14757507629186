.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.footer p {
    font-weight: 500;
    font-family: var(--primaryFont);
    font-size: 11px;
}

.footer p span {
    font-size: 24px;
}

.footer--img {
  --img-size: 40px;
  transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}
