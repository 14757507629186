.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    letter-spacing: 1px;
}
.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > h3 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    line-height: 190%;
    text-align: center;
}

.projectContent > img {
    /* width: 100%; */
    /* height: 65%; */
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    object-fit: cover;
    height: 250px;
    width: 310px;
    flex:60%;
    transition: opacity 0.7s 0.3s;
    
}

.singleProject:hover img{
    /* opacity: 0.7;
    filter: grayscale(1); */
    filter: blur(4px);
    transition: 1s filter ease-in-out;
-webkit-transition: 1s -webkit-filter ease-in-out;
-moz-transition: 1s -moz-filter ease-in-out;
-ms-transition: 1s -ms-filter ease-in-out;
-o-transition: 1s -o-filter ease-in-out;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
    transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 160px;
    left: 0;
    top: 0;
    transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    transform: translate(-2%,40%);
}
  
.singleProject .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    transform: translateX(100%);
    transition: transform 0.5s 0.3s;
    padding: 0.825rem;
}
  
.singleProject:hover .project--lang{
    transform: translateX(0);
}

.project--lang > span {
    font-family: var(--primaryFont);
    font-weight: 500;
    word-break: break-word; 
    line-height: 100%;
}