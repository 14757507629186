

.clients > div {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}


.clients-image {
    width: 120px;
}

.clients-image-inverted {
    width: 120px;
    -webkit-filter: invert(11); /* safari 6.0 - 9.0 */
          filter: invert(1);
}


.client--name{
    justify-content: center;
    align-items: center;
     
}


.client--name h3{
    font-family: var(--primaryFont);
    font-size: 20px;
    margin-top: 1rem;
    text-align: center;
    max-width: 200px;
    justify-content: center;
    align-items: center;
}

.client-about-company{
    font-family: var(--primaryFont);

    
   
}

/* .client-about-company a {
 color: darkred;
 font-weight: bold;

}

.client-about-company a :hover{
    color: white;
   transform: scale(1.1);
}
 */

.vertical-line {
  border-left: 5px solid black;
  width: 5px;
  height: 50px;
    margin: 0 auto;
}


.client-about-company {
  border-radius: 25px;
  border: 5px solid black;
  padding: 20px;
  margin-bottom: 40px;
  margin-left: 50;

    align-items: center;
    display: flex;
    flex-direction: column;
}



.client-about-company p{
    font-size: 15px;
    text-align: 'justify';
    padding-top: 20px;
}



.clientsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.clientsDescription p{
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size:  0.95rem;
    text-align: center;
}

.clientsDescription h5 {
    font-family: var(--primaryFont);
    font-weight: normal;
    font-size: 1.3rem;
    text-align: center;
}

.clientsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
}

.client--scroll {
    width: 100%;
    margin: 0 2rem;
}


.client--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 35px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    transition: 300ms ease-in-out;
}

.client--box:hover  {
    transform: scale(1.15);
}


.client--box > img {
    height: 50px;
    pointer-events: none;
}


.client--scroll h6 {
    font-family: var(--primaryFont);
    font-style: bold;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .clients {
        padding: 2rem 1rem 1rem 1rem;
    }
    .clientsContainer {
        padding: 1.5rem;
        margin-top: 1.5rem;
    }
}


@media screen and (max-width: 992px) {
    .clients {
        padding: 1rem;
        min-height: 100%;
    }
    .clientsContainer {
        padding: 1rem;
        margin: 1rem 0;
    }
    .clientsHeader h2 {
        font-size: 3.2rem;
    }
    .client--box {
        width: 150px;
        height: 150px;
        padding: 2rem 1rem;
    }

    .client--box > img {
        height: 45px;
    }


  

}

@media screen and (max-width: 800px) {
    .clients {
        padding: 0.5rem;
    }
    .clientsContainer {
        padding: 0.5rem;
    }
    .clientsHeader h2 {
        font-size: 3rem;
    }

    .client--scroll {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .client--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .client--box > img {
        height: 40px;
    }

    .clientsHeader h2 {
        font-size: 2.5rem;
    }

    .client--name h3 {
        font-size: 18px;
        margin-top: 1rem;
        font-family: var(--primaryFont);
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}