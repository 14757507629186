
@import "../common/common.css";

.svg-invert {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
            filter: invert(100%);
  }

.experience{
    padding: 0px 20px;
    min-height: 50vh;
    font-family: var(--primaryFont);
    min-height: 100vh;
    position: relative;
}

.experience-body {
   
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    height: fit-content;
   
}

.experience-body > div{
    padding: 0px 1.2rem;
}

.experience-card-description{
    align-items: center;
    justify-content: center;
}

.experience-list{
     padding: 0px 1.2rem;
     
}


.experience-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: fit-content;
}

.experience-card-description {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: fit-content;
}

.experience-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
   padding: 0px 100px 0px 100px;
    text-align: center;
}

.experience-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
}

.experience-image > img{
    pointer-events: none;
    width: 80%;
    padding: 1.2rem;
}

.expcard-img > img {
    width: 50%;
    flex: 0.5;
}


.experience-img > img {
    width: 50%;
    flex: 0.5;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .experience-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .experience-description > p {
      font-size: 1.15rem;
    }
}

@media screen and (max-width: 992px){


    .experience-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .experience-description{
        flex: 0.6;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .experience-card-description{
        flex: 0.6;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }


    .experience-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .experience-description > p {
        font-size: 1.1rem;
    }

    .experience-img > img {
        width: 250px;
        flex: 0.4;
    }

}

@media screen and (max-width: 800px){

  .experience-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .experience-description > h2 {
    font-size: 2.5rem;
  }

    .experience-description > p{
      font-size: 0.95rem;
    }

    .experience-img > img {
        width: 200px;
    }
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;

    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.expcard-img {
    border-radius: 29%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    flex: 0.6;
    pointer-events: none;
}

.experience-details {
    margin-left: 1.5rem;
    flex:0.65;
}

.experience-details > h6 {
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-size: 1.225rem;
    font-weight: 600;
    text-align: center;
}

.experience-details > h5 {
    font-size: 1.15rem;
    font-weight: 600;
    text-align: center;
}





@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}

