.backToTop {
    position: fixed; 
    right: 50%;
    bottom: 50px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        font-size: 2.75rem;
    }
}