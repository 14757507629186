.page-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;

}

.page-detail--container {
  display: flex;
  flex: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  
}

.page-detail--img {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.page-detail--container-left {
  flex: 35%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  min-height: 300px;
  
}

.page-detail--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: transform 0.5s;
}

.page-detail--social:hover {
  transform: scale(1.2);
}

.page-detail--container-right {
  
  flex: 65%;
  min-height: 76vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  
}

@media (max-width: 1100px) {
  .page-detail--img {
    --img-size: 350px;
  }
}

@media (max-width: 900px) {
  .page-detail--img {
    --img-size: 300px;
  }

  .page-detail--social {
    font-size: 30px;
  }
 
}

@media (max-width: 600px) {
  .page-detail--container {
    flex-direction: column;
    justify-content: space-between;
  }
  .page-detail--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #eaeaea;
    transform: translate(-50%, -50%);
  }
  .page-detail--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .page-detail--social {
    display: none;
  }
  .page-detail--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }

}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .page-detail {
    height: 100%;
  }

  .page-detail--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}
