:root {
    /* --primaryFont: 'Poppins', sans-serif; */
    --primaryFont: 'Montserrat', sans-serif;
    /* --primaryFont: 'Raleway', sans-serif; */
    
}



.slide-forward-enter, .slide-forward-exit {
    transition: transform 1000ms ease-out;
}
.slide-forward-enter {
    transform: translateX(100%);
}
.slide-forward-enter.slide-forward-enter-active {
    transform: translateX(0%);
}
.slide-forward-exit {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    transform: translateX(0%);
}
.slide-forward-exit-active {
    transform: translateX(100%);
}   
.slide-backward-enter, .slide-backward-exit {
    transition: transform 1000ms ease-out;
}
.slide-backward-enter {
    transform: translateX(-100%);
}
.slide-backward-enter.slide-backward-enter-active {
    transform: translateX(0%);
}
.slide-backward-exit {
    position: absolute; 
    top: 0;
    left: 0; 
    width: 100%; 
    transform: translateX(0%);
}
.slide-backward-exit-active {
    transform: translateX(-100%);
}



